@import url('https://fonts.googleapis.com/css?family=Rakkas');

$red: #e4001c;
$green: #24862f;
$bg: white;
$sigFont: Rakkas, sans-serif;

.site-content.chinesedelight {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23eefdff' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23dcfaff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23caf7ff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23b6f5ff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23a1f2ff' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%238edbee' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%237bc4dc' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%2368adcb' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235597bb' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%234182aa' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;

}
.chinesedelight {
  font-family: $sigFont;
  .specials {
    display: inline-block;
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
    background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
  }
  .cart.minimized {
    // background: url(http://mexcocinacafe.com/wp-content/uploads/2014/05/wood.jpg) no-repeat;
    padding: 0.4em 0.5em 0.1em 0.5em;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23eefdff' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23dcfaff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23caf7ff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23b6f5ff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23a1f2ff' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%238edbee' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%237bc4dc' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%2368adcb' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235597bb' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%234182aa' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 2px solid black;
    .nav-bar-logo {
      position: relative;
    }
  }
  a.checkout-button.button {
    // font-family: 'Bungee Inline', sans-serif;
    background: $red;
    // font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }

  .category-name .text {
    position: relative;
    text-transform: lowercase;
    font-family: $sigFont;
    font-weight: 400;
    font-size: 1.8em;
    background: none;
    color: black;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    // color: whtie;
    // text-shadow: 0px 1px 0 black;

  }
  .category-name .text::before {
    content: " ";
    display: inline-block;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_90/v1551987209/danstuff/okf-fire-icon.png) no-repeat;
    background-size: contain;
    width: 50px;
    height: 45px;
    position: absolute;
    left: -0.15em;
    top: -0.2em;
  }
  .food-name {
    // text-transform: lowercase;
    // text-transform: capitalize;
  }
  .section-header {
    text-align: center;

    // color: white;
    font-weight: 600;
    padding: 0.5em 0.8em;
    h2 {
      font-weight: 800;
      color: $red;
      // text-transform: uppercase;
      // color: black;
    }
  }
  .splash {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 1em;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    h2 {
      color: black;
      // color: $red;
    }
    .catch-phrase {
      font-size: 1em;
    }
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }
  .landing-page {
    margin: 0;
    display: block;
    min-height: 200px;
    color: black;
  }
  .traditional > .section-header {
    height: 300px;
    h2 { color: black; }
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,f_auto,w_1280/v1544208848/danstuff/recipe.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .landing-section {
    background: none;
    // padding: 2em 0;
    .content {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      padding: 0 0.8em;
      font-size: 0.8em;
    }
  }

  .about-us > .section-header {
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,f_auto,w_1280/v1544212860/danstuff/about-us-splash.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;

    h2 {
      color: white;
    }
  }
  .about-us .content {
    max-width: 720px;
    margin: 0 auto;
    padding: 0 1em;
    h3 {
      // font-weight: 800;
      font-size: 1.5em;
      margin: 1em 0;
      text-align: center;
    }
    p {
      margin: 1.5em 0;
      line-height: 1.5em;
    }
  }
  .delicious-menu .content {
    color: black;
    p { margin:1em 0;}
  }
  .landing-section-link {
    text-transform: uppercase;
  }
  .pages-nav {
    font-family: $sigFont;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    // background: white;
    // color: black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  $borderWidth: 4px;
  $paddingY: 0.35em;
  a.page-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid white;
    &:hover, &.active {
      border-bottom: $borderWidth solid black;
    }
  }

  a.order-online-link {
    text-transform: uppercase;
    // font-family: 'Helvetica', sans-serif;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
    position: relative;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .ordering-header-wrapper {
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    .text {
      padding: 1em;
      background: rgba(0,0,0,0.5);
    }
  }
  .landing-page-wrapper {
    position: relative;
    background: none;
  }
  .landing-page {
    // height: 540px;
    margin-bottom: 0;
  }
  .fire-bg {
    height: 100%;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1552768061/danstuff/hq-wok.gif) no-repeat;
    background-size: cover;
    background-position: center;

  }

  .video-bg {
    // filter: brightness(0.5);
    position: relative;
    width: auto;
    min-width: 100%;

    height: auto;
    min-height: 100%;

    background-size: cover;
  }
  video {
    display: block;

  }
  .video-container {

    width: 100%;
    // height: 100%;
    height: 450px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
    // max-width: 960px;
    overflow: hidden;
    // position: absolute;
    top: 0;
    right: 0;
  }
  .fire-gif {
    height: 450px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
  }
}
